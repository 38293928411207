document.addEventListener("DOMContentLoaded", function () {
  document.addEventListener("scroll", window.lazyLoad);
  window.addEventListener("resize", lazyLoad);
  window.addEventListener("orientationchange", lazyLoad);
  updateLazyImages();
  lazyLoad();
});

window.updateLazyImages = function () {
  window.lazyImages = [].slice.call(document.querySelectorAll("img.lazy-load"));
}

window.lazyLoad = function () {
  let active = false;

  if (active === false) {
    active = true;

    setTimeout(function () {
      lazyImages.forEach(function (lazyImage) {
        if ((lazyImage.getBoundingClientRect().top <= (window.innerHeight + 200) && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
          lazyImage.src = lazyImage.dataset.src;
          if (typeof lazyImage.dataset.srcset !== "undefined") {
            lazyImage.srcset = lazyImage.dataset.srcset;
          }
          lazyImage.classList.remove("lazy-load");

          lazyImages = lazyImages.filter(function (image) {
            return image !== lazyImage;
          });

          if (lazyImages.length === 0) {
            document.removeEventListener("scroll", window.lazyLoad);
            window.removeEventListener("resize", lazyLoad);
            window.removeEventListener("orientationchange", lazyLoad);
          }
        }

      });

      active = false;
    }, 200);
  }
};
